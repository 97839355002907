export default function About() {
	function renderSmall() {
		return (
			<div className={'d-lg-none'} style={{ margin: '20px' }}>
				<div
					className={'d-flex flex-row flex-wrap justify-content-center who-am-i'}
					style={{ gap: '10px' }}
				>
					<div style={{ width: '400px' }}>
						<img className={'img-fluid'} src={'img/Julija_Hauser_portrait.jpg'} alt='Portrait Julija Hauser'/>
					</div>
					<div style={{ width: '400px' }}>
						<h1>Wer bin ich</h1>
						<p>
							Ich kam als Quereinsteigerin aus einem medizinischen Beruf in die
							Homöopathie. <span className="bold-gold">Von 2017 bis 2022</span>{' '}
							absolvierte ich die Ausbildung zur dipl. Homöopathin in SHI Homöopathie
							Schule in Zug. <span className="bold-gold">2022 bis jetzt:</span>{' '}
							Weiterbildungen in Homöopathie und Arbeit in eigener Praxis.
						</p>

						<p style={{ marginBottom: '0px' }}>
							<span className="bold-gold">Sprachen:</span> Deutsch, Russisch
						</p>
					</div>
				</div>
			</div>
		)
	}

	function renderBig() {
		return (
			<div className={'d-none d-lg-block d-xl-block'}>
				<div className="container about d-flex justify-content-end">
					<div className="who-am-i" style={{ width: '400px' }}>
						<h1>Wer bin ich</h1>
						<p>
							Ich kam als Quereinsteigerin aus einem medizinischen Beruf in die
							Homöopathie. <span className="bold-gold">Von 2017 bis 2022</span>{' '}
							absolvierte ich die Ausbildung zur dipl. Homöopathin in SHI Homöopathie
							Schule in Zug. <span className="bold-gold">2022 bis jetzt:</span>{' '}
							Weiterbildungen in Homöopathie und Arbeit in eigener Praxis.
						</p>

						<p style={{ marginBottom: '0px' }}>
							<span className="bold-gold">Sprachen:</span> Deutsch, Russisch
						</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{renderSmall()}
			{renderBig()}
		</>
	)
}
