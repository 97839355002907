type NavigationType = {
	name: string
	href: string
	aria: string
}

export default function Navigation() {
	const navigation: NavigationType[] = [
		{
			name: 'Was ist Homöopathie?',
			href: '#page-what-is-it',
			aria: 'Was ist Homöopathie?',
		},
		{
			name: '7 Gründe',
			href: '#page-reasons-for-it',
			aria: '7 Gründe für Homöopathie',
		},
		{
			name: 'Wo hilfts?',
			href: '#page-help',
			aria: 'Welche Beschwerden können mit Homöopathie gelindert werden?',
		},
		{
			name: 'Über mich',
			href: '#page-about',
			aria: 'Informationen über mich und meinen Standort',
		},
		{
			name: 'Tarife',
			href: '#page-rate',
			aria: 'Informationen über Preise und Tarife',
		},
		{
			name: 'Termine',
			href: '#page-calendar',
			aria: 'Termine direkt online buchen',
		},
		{ name: 'Kontakt', href: '#page-contact', aria: 'Kontaktinformationen' },
	]

	function renderNavigation(navigationArray: NavigationType[]): JSX.Element {
		return (
			<div>
				{navigationArray.map((e: NavigationType, i: number) => {
					return (
						<div key={i} className="zoom">
							<a href={`/${e.href}`} aria-label={e.aria}>
								{e.name}
							</a>
						</div>
					)
				})}
			</div>
		)
	}
	return (
		<nav className="my-navigation container">
			<img src="img/logo.jpg" className="img-fluid" alt="Logo R&J Homeopathy" />
			<div>
				{renderNavigation(navigation.slice(0, 3))}
				{renderNavigation(navigation.slice(3, 9))}
			</div>
		</nav>
	)
}
