export default function Contact() {
	return (
		<div className="background-color-base">
			<div className="container normal" style={{ paddingBottom: '20px' }}>
				<h1>Kontakt</h1>
			</div>
			<div className="container d-flex">
				<div>
					<a
						href="https://www.google.com/maps/place/Dufourstrasse+106,+8008+Z%C3%BCrich"
						target="_blank"
					>
						<img
							src="img/map_zuerich.jpg"
							className="img-fluid zoom"
							alt="Karte Standort Zürich"
						/>
					</a>
				</div>
				<div>
					<a
						href="https://www.google.com/maps/place/B%C3%B6sch+41,+6331+H%C3%BCnenberg"
						target="_blank"
					>
						<img
							src="img/map_huenenberg.jpg"
							className="img-fluid zoom"
							alt="Karte Standort Hünenberg"
						/>
					</a>
				</div>
			</div>
			<div className="container normal">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						gap: '40px',
					}}
				>
					<div>
						Homöopathie Praxis
						<br />
						Julija Hauser dipl. Homöopathin SHI
						<br />
						<a href="mailto:julija.hauser@rj-homeopathy.ch">
							julija.hauser@rj-homeopathy.ch
						</a>
						<br />
						<a href="tel:+41793103782">079 310 37 82</a>
					</div>

					<div>
						<strong>Mo–Do/Fr Nachmittag</strong>
						<br />
						Oberdorfstrasse 6
						<br />
						6340 Baar
					</div>
				</div>
			</div>
		</div>
	)
}
